.powered {
    font-size: 8px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5);
    padding-left: 20px;
    top: -20px;
    position: relative;
    width: 99px;
}

.fixed {
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 1000;
    left: 0;
    top: 0;
    box-shadow: 0 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.main-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: block;
    width: 100%;
    height: 100px;
}

.top-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #304830;
    color: #fff;

    .header {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .logo {
        background-repeat: no-repeat;
        background-position: center;
        width: 90px;
        height: 70px;
        margin: 10px;
        background-size: contain;
    }

    .title {
        font-weight: 600;
        font-size: 18px;
    }

    .subtitle {
        font-weight: 300;
        font-size: 16px;
    }
}

.subscribe {
    white-space: nowrap;
    padding: 10px;
    background-color: #0dd10b;
    color: white;
    border-radius: 2px;
    height: 20px;
    text-decoration: none;
    margin: 0 30px 0 0;
}

.mobile-search {
    background-color: #3d3d3d;
    padding: 5px;
    display: none;
}

@media screen and (min-width: 1023px) {
    .main-bg {
        height: 270px;
    }
}

@media screen and (max-width: 1000px) {
    .top-container {
        .logo {
            height: 40px;
        }
    }

    .desktop-search {
        display: none;
    }

    .mobile-search {
        display: block;
    }
}

@media screen and (max-width: 660px) {
    .header {
        .title, .subtitle {
            font-size: 12px;
        }

        .top-container .logo {
            width: 50px;
            margin: 0;
        }
    }
    .subscribe,.download-btn {
        margin: 0 10px 0 0;
        font-size: 13px;
    }
}

