.top-menu {
  right: 10px;
  top: 20px;
  position: absolute;
  z-index: 100;

  a {
    margin: 0 10px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    padding: 10px;
    background-color: #0dd10b;
    border-radius: 2px;
    height: 20px;
  }
}

@media screen and (max-width: 800px) {
  .top-menu {
    right: 0;

    a {
      font-size: 10px;
      margin: 0 10px;
    }
  }
}
